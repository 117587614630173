body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text:hover{
  color:black;
}
.text-wrap:hover{
  cursor: pointer;
}
.link-social{
  color:white;
}
.link-social:hover{
  color:rgb(255 204 67)
}
.widget.widget_list .list-item > a:before {
    background-color: white;
}
.widget.widget_list .list-item > a:hover {
    color: black;
}
.widget.widget_list .list-item > a:hover:before {
    background-color: black;
}